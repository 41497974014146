import { useMemo, useState } from "react";
import {
  Box,
  Fab,
  Typography,
  Chip,
  ChipProps,
  Link,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import { capitalize, flatten, keyBy } from "lodash";
import StepIndicator from "../../common/StepIndicator";
import { Step } from "../../common/StepIndicator/StepIndicator";
import theme from "../../../theme";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import {
  GetTodayTripsQuery,
  ShipmentEventInput,
  ShipmentEventType,
  ShipmentLocationType,
  Status,
} from "../../../graphql/generated";
import { ShipmentLocationDetails } from "../../shipment/ShipmentSummary/ShipmentSummary";
import { Stack } from "@mui/system";
import { formatMileage } from "../../../utils/labels/formatDistance";
import driverLabel from "../../../utils/labels/driverLabel";
import {
  getTripCurrentLocation,
  getTripNextLocation,
} from "../../trip-planning/TripTimeline/TripTimeline";
import LoadingOverlay from "../../common/LoadingOverlay";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import enumLabel from "../../../utils/labels/enumLabel";
import statusKey from "../../../utils/i18n/statusKey";

type TripSummaryData = GetTodayTripsQuery["trips"]["data"][0];

export type TripSummaryType = Omit<TripSummaryData, "shipments"> & {
  shipments: Pick<
    TripSummaryData["shipments"][0],
    "_id" | "shipmentNumber" | "events" | "charges"
  >[];
};

export type TripSummaryProps = {
  trip: TripSummaryType;
  loading?: boolean;
  onClick?: () => void;
  onStart?: (trip: TripSummaryType) => void;
  onShipmentEventLog?: (event: ShipmentEventInput) => void;
  readonly?: boolean;
  singleClickTripCompletionEnabled?: boolean;
};

const TripSummary = ({
  trip,
  onClick,
  onStart,
  onShipmentEventLog,
  loading,
  readonly,
  singleClickTripCompletionEnabled,
}: TripSummaryProps) => {
  const { t } = useTranslation(["trips", "orders", "common"]);
  const getTripStatusLabel = useMemo(
    () => (status: Status) => {
      let color: ChipProps["color"] = "default";
      switch (status) {
        case Status.InProgress:
          color = "lightPrimary";
          break;
        case Status.Complete:
          color = "primary";
          break;
        // todo add delayed status
        default:
          break;
      }
      return (
        <Chip
          label={capitalize(
            t("trips:statusLabel", {
              status: statusKey[status],
              defaultValue: enumLabel(status),
            })
          )}
          color={color}
        />
      );
    },
    [t]
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const allShippedGoodsById = keyBy(
    flatten(
      trip.shipmentLocations.map((location) => location.shippedGoods || [])
    ),
    "_id"
  );

  const shipmentLocationsCount = trip.shipmentLocations.length;
  const firstShipmentLocation = trip.shipmentLocations[0];
  const lastShipmentLocation =
    trip.shipmentLocations[shipmentLocationsCount - 1];
  const separatorLength = shipmentLocationsCount > 2 && !isExpanded ? 20 : 40;

  const nextLocation =
    getTripCurrentLocation(trip) || getTripNextLocation(trip);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventDate, setEventDate] = useState(new Date());
  const [eventType, setEventType] = useState<ShipmentEventType | null>(null);
  const [eventLabel, setEventLabel] = useState<string | null>(null);
  const [eventDescription, setEventDescription] = useState<string | null>(null);
  const [location, setLocation] =
    useState<typeof trip.shipmentLocations[0] | null>(null);

  const onShipmentEventLogForLocation =
    trip.carrier || trip.status === Status.InProgress
      ? (
          location: typeof trip.shipmentLocations[0],
          eventType: ShipmentEventType
        ) => {
          if (!location.shipmentId) {
            return;
          }
          setEventDate(new Date());
          setLocation(location);
          setEventType(eventType);
          setIsModalOpen(true);
        }
      : undefined;

  const betweenSteps: Step[] =
    shipmentLocationsCount > 2 && !isExpanded
      ? [
          {
            id: "between-indicator",
            indicator: (
              <Typography
                sx={{
                  fontSize: 8,
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                }}
              >{`+${shipmentLocationsCount - 2}`}</Typography>
            ),
            color: theme.palette.primary.main,
            separatorLength: 20,
            onIndicatorClick: (event) => {
              event.stopPropagation();
              setIsExpanded(true);
            },
          },
        ]
      : trip.shipmentLocations
          .slice(1, shipmentLocationsCount - 1)
          .map((_shipmentLocation) => ({
            id: `${_shipmentLocation._id}`,
            content: (
              <ShipmentLocationDetails
                allShippedGoodsById={allShippedGoodsById}
                shipmentLocation={_shipmentLocation}
                shipment={trip.shipments.find(
                  (shipment) => shipment._id === _shipmentLocation.shipmentId
                )}
                onShipmentEventLog={
                  !trip.carrier && _shipmentLocation._id === nextLocation?._id
                    ? onShipmentEventLogForLocation?.bind(
                        null,
                        _shipmentLocation
                      )
                    : undefined
                }
              />
            ),
            color:
              _shipmentLocation.locationType === ShipmentLocationType.Pickup
                ? theme.palette.primary.main
                : theme.palette.accent.main,
            separatorLength: 40,
          }));

  const steps: Step[] = [
    {
      id: "firststep-indicator",
      hideFirstLine: true,
      content: (
        <ShipmentLocationDetails
          allShippedGoodsById={allShippedGoodsById}
          shipmentLocation={firstShipmentLocation}
          shipment={trip.shipments.find(
            (shipment) => shipment._id === firstShipmentLocation.shipmentId
          )}
          onShipmentEventLog={
            !trip.carrier && firstShipmentLocation._id === nextLocation?._id
              ? onShipmentEventLogForLocation?.bind(null, firstShipmentLocation)
              : undefined
          }
        />
      ),
      color: theme.palette.primary.main,
      separatorLength: separatorLength,
    },
    ...betweenSteps,
    {
      id: "laststep-indicator",
      hideLastLine: true,
      content: (
        <ShipmentLocationDetails
          allShippedGoodsById={allShippedGoodsById}
          shipmentLocation={lastShipmentLocation}
          shipment={trip.shipments.find(
            (shipment) => shipment._id === lastShipmentLocation.shipmentId
          )}
          onShipmentEventLog={
            !trip.carrier && lastShipmentLocation._id === nextLocation?._id
              ? onShipmentEventLogForLocation?.bind(null, lastShipmentLocation)
              : undefined
          }
        />
      ),
      color: theme.palette.accent.main,
      separatorLength: separatorLength,
    },
  ];

  return (
    <Box
      sx={{
        borderRadius: 5,
        p: 2,
        backgroundColor: theme.palette.primaryBackground.main,
        position: "relative",
        cursor: onClick ? "pointer" : undefined,
      }}
      onClick={onClick}
    >
      <LoadingOverlay loading={loading || false} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            fontSize: 13,
          }}
        >
          {trip.driver ? driverLabel(trip.driver) : ""}
        </Typography>
        <Stack
          spacing={2}
          direction="row"
          sx={{
            alignItems: "center",
          }}
        >
          <Link href={`/trips/details/${trip._id}`} target="_blank">
            {capitalize(t("trip", "Trip"))} {trip.tripNumber}
          </Link>
          <Typography color="primary">
            {formatMileage(trip.routeDistance)}
          </Typography>
          {getTripStatusLabel(trip.status)}
        </Stack>
      </Box>
      <StepIndicator steps={steps} />
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {isExpanded && (
          <Fab
            color="primary"
            size="small"
            sx={{ position: "absolute", bottom: -25, zIndex: 1 }}
            onClick={(event) => {
              event.stopPropagation();
              setIsExpanded(false);
            }}
            aria-label="toggle-wide"
          >
            <ExpandLessOutlinedIcon sx={{ color: "white" }} />
          </Fab>
        )}
      </Box>
      {!trip.carrier &&
      ![Status.InProgress, Status.Complete, Status.Deleted].includes(
        trip.status
      ) &&
      onStart ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: isExpanded ? 1 : 0,
          }}
        >
          <Button
            variant="outlined"
            onClick={(event) => {
              event.stopPropagation();
              onStart(trip);
            }}
            disabled={readonly}
          >
            {t("trips:start", "Start trip")}
          </Button>
        </Box>
      ) : onShipmentEventLog ? (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: isExpanded ? 1 : 0,
          }}
        >
          <Button
            variant="outlined"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              onShipmentEventLogForLocation?.(
                nextLocation as typeof trip.shipmentLocations[0],
                ShipmentEventType.CheckCall
              );
            }}
            disabled={readonly}
          >
            {t("trips:checkCall", "Check Call")}
          </Button>
          {singleClickTripCompletionEnabled &&
          trip.status !== Status.Complete ? (
            <Button
              variant="outlined"
              onClick={(event) => {
                event.stopPropagation();
                onShipmentEventLogForLocation?.(
                  nextLocation as typeof trip.shipmentLocations[0],
                  ShipmentEventType.TripEnd
                );
              }}
              disabled={readonly}
              size="small"
            >
              {t("trips:completeTrip", "Complete Trip")}
            </Button>
          ) : null}
        </Stack>
      ) : null}
      <Dialog
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <DialogTitle>
          {t("trips:selectedEventDate", "Select the event date")}
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ mt: 2 }} spacing={2}>
            <DateTimePicker
              ampm={false}
              label={capitalize(t("trips:event.date", "Event date"))}
              value={eventDate}
              onChange={(date) => {
                if (!date) {
                  return;
                }
                setEventDate(date);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            {eventType === ShipmentEventType.CheckCall ? (
              <>
                <TextField
                  size="small"
                  label={capitalize(t("trips:event.one", "Event"))}
                  name="eventLabel"
                  value={eventLabel}
                  onChange={(event) => {
                    setEventLabel(event.target.value);
                  }}
                />
                <TextField
                  size="small"
                  label={capitalize(t("trips:event.notes", "Notes"))}
                  name="eventDescription"
                  multiline
                  minRows={3}
                  maxRows={10}
                  value={eventDescription}
                  onChange={(event) => {
                    setEventDescription(event.target.value);
                  }}
                />
              </>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            {t("common:cancel", "Cancel")}
          </Button>
          <Button
            onClick={() => {
              if (!location?.shipmentId) {
                return;
              }
              if (!eventType) {
                return;
              }
              setIsModalOpen(false);
              onShipmentEventLog?.({
                type: eventType,
                label: eventLabel,
                description: eventDescription,
                trip: trip._id,
                shipment: location.shipmentId,
                shipmentLocation: location._id,
                location: {
                  latitude: 0,
                  longitude: 0,
                },
                date: eventDate,
              });
              setEventLabel(null);
              setEventDescription(null);
            }}
          >
            {t("common:save", "Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TripSummary;
