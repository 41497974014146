import { omit, range } from "lodash";
import {
  NewDriverShiftAssignmentInput,
  GetDriverShiftAssignmentDetailsQuery,
  useAddDriverShiftAssignmentMutation,
  useEditDriverShiftAssignmentMutation,
  useGetDriverShiftAssignmentDetailsQuery,
  useAddDriverShiftMutation,
  NewDriverShiftInput,
  useGetDriverShiftDetailsQuery,
  useEditDriverShiftMutation,
  useGetDriverDetailsQuery,
} from "../../../graphql/generated";
import DriverShiftAssignmentFormModal, {
  DriverShiftAssignmentFormModalProps,
} from "./DriverShiftAssignmentFormModal";
import LoadingOverlay from "../../common/LoadingOverlay";
import { utcToZonedTime } from "date-fns-tz";

type DriverShiftAssignmentFormModalContainerProps = Omit<
  DriverShiftAssignmentFormModalProps,
  "driverShiftAssignment" | "onSubmit" | "error"
> & {
  driverShiftAssignmentId?: string;
  onSaved?: (driverShiftAssignmentId: string) => void;
};

type DriverShiftAssignmentDetailsData = Exclude<
  GetDriverShiftAssignmentDetailsQuery["driverShiftAssignment"],
  null | undefined
>;

const driverShiftAssignmentToInput = (
  driverShiftAssignment: DriverShiftAssignmentDetailsData | null
): NewDriverShiftAssignmentInput | null => {
  if (!driverShiftAssignment) {
    return null;
  }
  return {
    ...omit(driverShiftAssignment, "_id", "driver", "driverShift"),
  };
};

const DriverShiftAssignmentFormModalContainer = (
  props: DriverShiftAssignmentFormModalContainerProps
) => {
  const driverShiftAssignmentQuery = useGetDriverShiftAssignmentDetailsQuery(
    {
      id: props.driverShiftAssignmentId || "",
    },
    {
      enabled: !!props.driverShiftAssignmentId,
    }
  );

  const addDriverShiftAssignmentMutation =
    useAddDriverShiftAssignmentMutation();
  const editDriverShiftAssignmentMutation =
    useEditDriverShiftAssignmentMutation();
  const addDriverShiftMutation = useAddDriverShiftMutation();
  const editDriverShiftMutation = useEditDriverShiftMutation();

  const existingDriverShiftAssignment =
    driverShiftAssignmentQuery?.data?.driverShiftAssignment || null;

  const submitDriverShiftAssignment = async (
    driverShiftAssignment: NewDriverShiftAssignmentInput,
    driverTimezone: string
  ) => {
    const driverShift =
      driverShiftAssignment.driverShiftId !== "NEW"
        ? (
            await useGetDriverShiftDetailsQuery.fetcher({
              id: driverShiftAssignment.driverShiftId,
            })()
          ).driverShiftById
        : null;
    if (driverShiftAssignment.driverShiftId === "NEW" || driverShift?.isAdhoc) {
      const zonedStartDate = utcToZonedTime(
        driverShiftAssignment.startDate,
        driverTimezone
      );
      const zonedEndDate = utcToZonedTime(
        driverShiftAssignment.endDate,
        driverTimezone
      );
      const { driverById: driver } = await useGetDriverDetailsQuery.fetcher({
        id: driverShiftAssignment.driverId,
      })();
      const newDriverShift: NewDriverShiftInput = {
        driverIds: [driverShiftAssignment.driverId],
        domicileId: driver.domicileId,
        startTime: {
          hour: zonedStartDate.getHours(),
          minute: zonedStartDate.getMinutes(),
        },
        endTime: {
          hour: zonedEndDate.getHours(),
          minute: zonedEndDate.getMinutes(),
        },
        days: range(
          zonedStartDate.getTime(),
          zonedEndDate.getTime(),
          24 * 60 * 60 * 1000
        ).map((time) => new Date(time).getDay()),
        label: `Custom Driver Shift`,
        isAdhoc: true,
      };
      const response =
        driverShiftAssignment.driverShiftId === "NEW"
          ? await addDriverShiftMutation.mutateAsync({
              newDriverShiftData: newDriverShift,
            })
          : await editDriverShiftMutation.mutateAsync({
              editDriverShiftData: newDriverShift,
              id: driverShiftAssignment.driverShiftId,
            });
      driverShiftAssignment.driverShiftId =
        "addDriverShift" in response
          ? response.addDriverShift._id
          : response.editDriverShift._id;
    }
    const newLinkingHasPeriod =
      !!driverShiftAssignment.startDate && !!driverShiftAssignment.endDate;
    const existingLinkingHasPeriod =
      !!existingDriverShiftAssignment?.startDate &&
      !!existingDriverShiftAssignment?.endDate;
    // If we are editing an existing link and set a period, we should create a new one
    // instead of overriding the existing one
    if (
      existingDriverShiftAssignment?._id &&
      (existingLinkingHasPeriod || !newLinkingHasPeriod)
    ) {
      const response = await editDriverShiftAssignmentMutation.mutateAsync({
        id: existingDriverShiftAssignment._id,
        editDriverShiftAssignmentData: driverShiftAssignment,
      });
      props.onSaved?.(response.editDriverShiftAssignment._id);
    } else {
      const response = await addDriverShiftAssignmentMutation.mutateAsync({
        newDriverShiftAssignmentData: driverShiftAssignment,
      });
      props.onSaved?.(response.addDriverShiftAssignment._id);
    }
    driverShiftAssignmentQuery.refetch();
    props.onClose();
  };

  if (props.driverShiftAssignmentId && driverShiftAssignmentQuery.isLoading) {
    return <LoadingOverlay loading />;
  }

  return (
    <DriverShiftAssignmentFormModal
      driverShiftAssignment={driverShiftAssignmentToInput(
        existingDriverShiftAssignment
      )}
      onSubmit={submitDriverShiftAssignment}
      loading={
        addDriverShiftAssignmentMutation.isLoading ||
        editDriverShiftAssignmentMutation.isLoading ||
        (!!props.driverShiftAssignmentId &&
          driverShiftAssignmentQuery?.isLoading)
      }
      error={
        addDriverShiftAssignmentMutation.error ||
        editDriverShiftAssignmentMutation.error
      }
      {...props}
    />
  );
};

export default DriverShiftAssignmentFormModalContainer;
